import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { CardOrientation, isRelativeUrl, StyledCard } from '../../../../../ui';
import { RouterLink } from '../../link/RouterLink';
import { useTranslation } from '../../../i18n/translate/useTranslation';
import Responsive from '../../../../../ui/utils/Responsive';
import { theme } from '../../../styles';

interface IBaseCardProps {
	fullyClickable?: boolean;
	linkHref: string;
	cardOrientation?: Responsive.IValueOrResponsiveValue<CardOrientation>;
	titleFontSize?: Responsive.IValueOrResponsiveValue<number>;
	children: ReactNode;
	variant: 'horizontal' | 'vertical';
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function BaseCard({
	fullyClickable = true,
	linkHref,
	cardOrientation = 'horizontal',
	titleFontSize = 32,
	variant,
	children,
}: IBaseCardProps) {
	const isExternalUrl = !isRelativeUrl(linkHref);
	const { t } = useTranslation();

	return (
		<CardStyled
			fullyClickable={fullyClickable}
			cardOrientation={cardOrientation}
			titleFontSize={titleFontSize}
		>
			<StyledLink
				href={linkHref}
				variant={variant}
				target={isExternalUrl ? '_blank' : undefined}
				aria-label={`Go to ${linkHref}${
					isExternalUrl ? ` ${t('generic_external_link_label')}` : null
				}`}
				removeUnderlineStyling
			>
				<>{children}</>
			</StyledLink>
		</CardStyled>
	);
}

const StyledLink = styled(RouterLink)<{ variant: 'horizontal' | 'vertical' }>`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-self: stretch;

	${({ variant }) =>
		variant === 'horizontal' &&
		`
			display: flex;
			flex-direction: row;
	`};
`;

export const CardStyled = styled(StyledCard)<{
	fullyClickable: boolean;
}>`
	flex-grow: 1;
	border-radius: 12px;
	margin-left: 0;
	margin-top: 0;
	position: relative;

	box-shadow: none;
	transition: transform 0.2s ease-in-out;

	&:hover {
		transform: translateY(-12px);
		box-shadow: none;
	}

	${StyledLink} {
		&:focus-visible {
			outline: ${theme.button?.primary?.focus?.outline};
			border-radius: 12px;
		}
	}
`;
