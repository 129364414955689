import styled from '@emotion/styled';
import { Css } from '../../../../ui';
import { Color } from '../../../shared/styles/Color';

const ContentCategoryTag = styled.span`
	background-color: ${Color.Surface};
	padding: 8px 12px;
	font-size: ${Css.pxToRem(12)};
	font-weight: 500;
	line-height: 14.4px;
	letter-spacing: 1px;
	text-transform: uppercase;
	border-radius: 4px;
`;

export default ContentCategoryTag;
