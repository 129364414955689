'use client';

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	Css,
	H2,
	IStyledCardProps,
	ITheme,
	StyledCardContent,
	StyledCardPicture,
} from '../../../../ui';
import BaseCard, {
	CardStyled,
} from '../../../shared/components/cards/Card/BaseCard';
import { formatFullDate } from '../../../../i18n/formatFullDate';
import { Color } from '../../../shared/styles/Color';
import { IContentCategoryEntry } from '../../../shared/api/contentful/types/IContentCategory';
import ContentCategoryTag from './ContentCategoryTag';
import { getResponsiveImageUrl } from '../../../shared/image/getResponsiveImageUrl';
import Responsive from '../../../../ui/utils/Responsive';

interface INewsCardProps extends IStyledCardProps {
	title: string | ReactNode;
	imageSource: string;
	linkHref: string;
	publicationDate?: string;
	summary?: string;
	variant: 'horizontal' | 'vertical';
	category?: IContentCategoryEntry | undefined;
	categoryLabelLeftTop?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NewsCard({
	title,
	linkHref,
	imageSource,
	publicationDate,
	summary,
	variant,
	category,
	categoryLabelLeftTop,
	...props
}: INewsCardProps) {
	return (
		<BaseCard linkHref={linkHref} variant={variant} {...props}>
			<CardPictureStyled
				source={getNewsCardImageSource(imageSource)}
				variant={variant}
				alt=""
				aria-hidden
			/>

			<CardContentStyled variant={variant}>
				{category && (
					<CategoryWrapper
						variant={variant}
						categoryLabelLeftTop={categoryLabelLeftTop}
					>
						<ContentCategoryTag>{category.fields.title}</ContentCategoryTag>
					</CategoryWrapper>
				)}
				{publicationDate && (
					<CardPublicationDate>
						{formatFullDate(publicationDate, 'MMM d, yyyy')}
					</CardPublicationDate>
				)}
				{typeof title === 'string' ? (
					<CardTitle variant={variant}>{title}</CardTitle>
				) : (
					title
				)}
				{summary && <CardSummary variant={variant}>{summary}</CardSummary>}
			</CardContentStyled>
		</BaseCard>
	);
}

function getNewsCardImageSource(imageSrc: string) {
	return getResponsiveImageUrl(imageSrc, {
		fit: 'thumb',
		w: 686,
		h: 428,
	});
}

const CardPictureStyled = styled(StyledCardPicture)<
	{ variant: 'horizontal' | 'vertical' },
	ITheme
>`
	overflow: hidden;
	border-radius: ${({ variant }) =>
		variant === 'horizontal' ? '12px 0 0 12px' : '12px 12px 0 0'};
	border: 1px solid ${Color.Neutral90};
	border-bottom: none;
	max-width: 100%;
	height: ${({ variant }) => (variant === 'horizontal' ? '188px' : 'auto')};
	width: ${({ variant }) => (variant === 'horizontal' ? '281px' : 'auto')};

	img {
		width: 100%;
		max-width: 100%;
		object-fit: cover;
		aspect-ratio: ${({ variant }) =>
			variant === 'horizontal' ? '281 / 189' : '64 / 40'};
	}
`;

const CardContentStyled = styled(StyledCardContent)<
	{ variant: 'horizontal' | 'vertical' },
	ITheme
>`
	flex: 1;
	justify-content: flex-start;
	gap: 5px;
	background-color: ${Color.Surface};
	padding: ${Css.pxToRem(16)};

	${({ variant }) =>
		variant === 'horizontal'
			? `
					border-radius: 0 12px 12px 0;
				`
			: `
					border-radius: 0 0 12px 12px;
				`}
`;

const CategoryWrapper = styled.div<{
	variant: 'horizontal' | 'vertical';
	categoryLabelLeftTop?: boolean;
}>`
	position: absolute;
	top: 8px;
	${({ variant, categoryLabelLeftTop }) =>
		variant === 'horizontal' || categoryLabelLeftTop
			? 'left: 8px;'
			: 'right: 8px;'}
	z-index: 2;
`;

const CardPublicationDate = styled.small`
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	letter-spacing: 0.6px;
	font-weight: 500;
	color: ${Color.Neutral30};
`;

export const CardTitle = styled(H2)<{ variant: 'horizontal' | 'vertical' }>`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 25px;
	font-size: ${({ variant }) =>
		variant === 'horizontal' ? `${Css.pxToRem(20)}` : `${Css.pxToRem(18)}`};
	color: ${Color.Background2};
	margin-bottom: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 0.1em;
	}

	${CardStyled}:hover & {
		color: ${Color.PrimaryDark};
	}
`;

const CardSummary = styled.div<{
	variant: 'horizontal' | 'vertical';
}>`
	font-size: ${Css.pxToRem(16)};
	font-weight: 300;
	line-height: ${Css.pxToRem(19)};
	color: ${Color.TextGrey};
	overflow: hidden;

	display: -webkit-box;
	-webkit-line-clamp: ${({ variant }) =>
		variant === 'horizontal' ? '4' : '3'};
	-webkit-box-orient: vertical;

	// Ensures long URLs break instead of overflowing. Prevents card from force-growing.
	overflow-wrap: break-word;
	word-break: break-word;

	a {
		text-decoration: underline;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		-webkit-line-clamp: 2;
	}
`;
