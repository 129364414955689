import { format } from 'date-fns';

// Defining known format types allows to to avoid inconsistency.
// These are currently the only formats used.
type FormatType = 'MM/dd/yyyy' | 'MMM d, yyyy' | 'MM/dd/yyyy - hh:mm a';

export function formatFullDate(
	date: string,
	formatType: FormatType = 'MM/dd/yyyy',
) {
	const parsedDate = new Date(date);
	return format(parsedDate, formatType);
}
